<template>
  <div :id="ele" style="width: 152px; height: 152px"></div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    ele: {
      type: String,
      default: "chart_box",
    },
  },
  data() {
    return {
      chartBox: null,
    };
  },
  watch: {
    data: {
      handler: function (val, oval) {
        if (
          val &&
          val.length > 0 &&
          JSON.stringify(val) !== JSON.stringify(oval)
        ) {
          this.chartBox.setOption(this.initBar());
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.chartBox) this.chartBox.setOption(this.initBar());
      else {
        let chart = this.$echarts.init(document.getElementById(this.ele));
        chart.setOption(this.initBar());
        this.chartBox = chart;
      }
    });
  },
  methods: {
    initBar() {
      const BorderWidth = "10";
      //反方向剩下部分的样式
      const placeHolderStyle = {
        color: "#EBF0F3",
        borderColor: "#EBF0F3",
        borderWidth: 10,
      };
      const percentTotal = this.data.length > 0 && this.data[0].percent;
      const series = [];
      const defaultRadius = 85,
        rates = 100 / this.data.length;
      this.data.map((item, index) => {
        const radiusNum = defaultRadius - index * rates,
          radius = [`${radiusNum}%`, `${radiusNum}%`];
        const itemSet = {
          type: "pie",
          clockwise: true, //顺时加载
          emphasis: {
            scale: false,
            itemStyle: {
              borderWidth: 0,
            },
          }, //鼠标移入变大
          silent: true,
          radius,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
        };
        series.push({
          name: `${item.name}-background`,
          ...itemSet,
          itemStyle: {
            borderWidth: BorderWidth,
          },
          data: [
            {
              value: 100,
              name: "placeHolder",
              itemStyle: placeHolderStyle,
            },
          ],
        });
        series.push({
          name: `${item.name}`,
          ...itemSet,
          itemStyle: {
            borderWidth: BorderWidth,
            shadowBlur: 8,
            borderColor: {
              type: "linear",
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: item.color,
            },
            shadowColor: item.shadowColor, //边框阴影
          },
          data: [
            {
              value:
                index === 0 && !percentTotal && item.percent
                  ? 100
                  : item.percent / percentTotal,
            },
            {
              value: 1 - item.percent / percentTotal,
              itemStyle: {
                opacity: 0,
              },
            },
          ],
        });
      });
      return { series };
    },
  },
};
</script>
<style lang="scss" scoped></style>
