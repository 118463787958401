<template>
  <div class="participants">
    <search-collapse title="" :senior="false" @handleSearch="getList">
      <template #search-left>
        <el-button
          type="primary"
          class="add"
          icon="el-icon-plus"
          @click="handleEdit"
          >新建</el-button
        >
        <el-button class="add export" plain @click="handleImport">
          <div class="flex-h flex-vc">
            <i class="iconfont icon-canyuren_up img_icon"></i>
            批量导入
          </div>
        </el-button>
      </template>
      <el-form class="el-form-custom" :inline="true" :model="queryCondition">
        <el-form-item label="在职状态">
          <el-select v-model="queryCondition.employ_status" placeholder="全部">
            <el-option
              v-for="item in $addAllSelect(tableMng.getTable('employ_status'))"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="queryCondition.department"
            placeholder="请输入部门信息"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="queryCondition.phone"
            placeholder="请输入手机号"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
    </search-collapse>
    <div class="row" ref="row" :style="`height:${rowHeight}`">
      <div class="col" v-show="showDataDemo">
        <div class="col-left">
          <el-table
            :data="tableData"
            highlight-current-row
            v-loading="tableLoading"
            @sort-change="changeSort"
            @row-click="rowClick"
            :row-class-name="tableRowClassName"
            ref="table"
            height="calc(100% - 64px)"
            class="custom_border"
          >
            <el-table-column
              prop="phone"
              label="手机号"
              min-width="140px"
              sortable="custom"
            />
            <af-table-column prop="name" label="姓名" min-width="180px">
              <template slot-scope="scope">
                <chacter-overflow :text="scope.row.name || '- -'" :limit="20" />
              </template>
            </af-table-column>
            <af-table-column
              prop="department"
              label="所属部门"
              min-width="120px"
            >
              <template slot-scope="scope">
                <chacter-overflow
                  :text="scope.row.department || '- -'"
                  :limit="20"
                />
              </template>
            </af-table-column>
            <el-table-column
              prop="employ_status"
              label="在职状态"
              min-width="130px"
              sortable="custom"
            >
              <template slot-scope="scope">
                {{
                  tableMng.getNameById("employ_status", scope.row.employ_status)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="id" label="操作" min-width="140px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleEdit(scope.$index, scope.row)"
                >
                  修改
                </el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button
                  type="text"
                  class="del_button"
                  @click="handleDelete(scope.$index, scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="ctime"
              label="创建时间"
              min-width="195px"
              sortable="custom"
            />
            <af-table-column prop="creator" label="创建人">
              <template slot-scope="scope">
                <chacter-overflow
                  :text="scope.row.creator || '- -'"
                  :limit="30"
                />
              </template>
            </af-table-column>
            <el-table-column prop="mtime" label="修改时间" min-width="195px" />
            <af-table-column prop="modifier" label="修改人" />
          </el-table>
          <!-- 分页 -->
          <pagination
            :total="total"
            :page-number.sync="queryCondition.page"
            :page-size.sync="queryCondition.limit"
            @pagination="getList"
          />
        </div>
        <div class="col-right">
          <el-tabs v-model="editableTabsValue" type="card">
            <el-tab-pane label="权益统计" name="0">
              <right-sta :data="relation_data" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <Empty v-show="!showDataDemo && requested" />
    </div>
    <Edit
      :data="edit_data"
      :visible="visible"
      @onClose="handleClose"
      @onSave="handleSave"
    />
    <FileImport
      :visible="import_visible"
      @onClose="handleClose"
      @onSave="handleSave"
    />
  </div>
</template>
<script>
import SearchCollapse from "@/components/base/search-collapse";
import Empty from "@/components/base/empty";
import RightSta from "./components/RightSta.vue";
import Edit from "./components/Edit.vue";
import FileImport from "./components/FileImport.vue";
import Pagination from "@/components/base/pagination";
import tableLife from "@/mixins/tableLife.js";
import api from "@/api";
export default {
  mixins: [tableLife],
  components: {
    SearchCollapse,
    Pagination,
    RightSta,
    FileImport,
    Empty,
    Edit,
  },
  data() {
    return {
      editableTabsValue: 0,
      defaultSortWord: "ctime",
      total: 0,
      queryCondition: {
        employ_status: "",
        department: "",
        phone: "",
        sort: "ctime",
        order: "desc",
        page: 1,
        limit: 20,
      },
      visible: false,
      import_visible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getApiTableData() {
      return api.participants.staffList(this.queryCondition);
      // const data = await api.participants.staffList(this.queryCondition);
      // return data;
    },
    // rowClickMethod() {
    //   const { user_id } = this.relation_data;
    //   if (!user_id) return;
    //   api.participants.statUser({ user_id: this.data.user_id }).then((res) => {
    //     if (res && Object.keys(res).length > 0) this.statData = res;
    //     console.log(res);
    //   });
    // },
    handleImport() {
      this.import_visible = true;
    },
    handleDelete(index, row) {
      let user_id = row.user_id;
      if (user_id) {
        this.$confirm(`确定删除吗？`, "删除", {})
          .then(async () => {
            await api.participants.staffDel({ user_id });
            this.$message.success("删除成功！");
            this.getList();
          })
          .catch(() => {});
      }
    },

    // 关闭编辑模态窗
    handleClose() {
      this.visible = false;
      this.import_visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.participants {
  height: 100%;
  .el-form-custom {
    text-align: right;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .add {
  }
  .export {
    padding-left: 12px;
    .img_icon {
      margin-right: 8px;
      font-size: 22px;
    }
  }
}
</style>
