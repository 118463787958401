<template>
  <el-dialog
    class="custom-dialog"
    :title="`${data && data.user_id ? '修改参与人' : '新建参与人'}`"
    :visible.sync="visible"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
  >
    <div>
      <el-form
        ref="form"
        :model="info"
        v-loading="getDetailLoading"
        :rules="formRules"
        hide-required-asterisk
        label-width="75px"
      >
        <el-form-item label="手机号" prop="phone">
          <el-input
            label=""
            v-model="info.phone"
            placeholder="输入参与人手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="info.name"
            placeholder="输入参与人姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="所属部门" prop="department">
          <el-input
            v-model="info.department"
            placeholder="输入参与人所属部门"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="在职状态">
          <el-select v-model="info.employ_status" placeholder="全部">
            <el-option
              v-for="item in tableMng.getTable('employ_status')"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleBeforeClose">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api";
import dialogLife from "@/mixins/dialogLife";
import { deepClone } from "@/utils/core";
const defaultInfo = {
  department: "",
  phone: "",
  name: "",
  employ_status: "1",
};

export default {
  mixins: [dialogLife],
  components: {},
  data() {
    return {
      infoBackup: deepClone(defaultInfo),
      info: deepClone(defaultInfo),
      formRules: {
        phone: [
          { required: true, message: "请输入参与人手机号", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    async getDetail() {
      if (!this.data || !this.data.user_id) return;
      this.getDetailLoading = true;
      let data = deepClone(this.data);
      // data.employ_status = Number(data.employ_status);
      this.info = data;
      this.infoBackup = data;
      this.getDetailLoading = false;
    },
    async submitMethod() {
      const { phone, name, department, employ_status, user_id } = this.info;
      if (user_id)
        await api.participants.staffEdit({
          phone,
          name,
          department,
          employ_status,
          user_id,
        });
      else
        await api.participants.staffAdd({
          phone,
          name,
          department,
          employ_status,
        });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.info = deepClone(defaultInfo);
      this.infoBackup = deepClone(defaultInfo);
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dialog {
  .el-dialog__footer {
    // padding: 0;
  }
}
</style>
