<template>
  <div class="right-sta" v-loading="loading">
    <div class="chart_title mt-5 flex-h flex-vc">
      期权权益
      <tip-text
        tip-text="<div>统计中的“授予总数”和“成熟总数”与授予记录<br/>中的“授予股数”和“已成熟数”不是简单的汇<br />总关系。</div><div>考虑了“已终止”状态的授予记录中“已回收数”<br />对统计中“授予总数”和“成熟总数”的影响。</div>"
      />
    </div>
    <div class="flex-h flex-vc flex-jsb">
      <div class="left">
        <ChartNum
          name="授予记录数"
          :num="option_stat.number"
          backgroundImage=""
          shadowColor=""
        />
        <ChartNum
          v-for="(item, idx) in optionStatRe"
          :key="idx"
          :name="item.name"
          :num="item.percent"
          :backgroundImage="item.lineColor"
          :shadowColor="item.shadowColor"
        />
      </div>
      <div class="chart-box">
        <RingEchart :data="optionStat" ele="option" />
      </div>
    </div>
    <div class="chart_title mt-20 flex-h flex-vc">
      股票权益
      <tip-text
        tip-text="<div>统计中的“授予总数”与授予记录中的“授予股<br />数”不是简单的汇总关系。</div><div>考虑了“已终止”状态的授予记录中“已回收数”<br />对统计中“授予总数”的影响。</div>"
      />
    </div>
    <div class="flex-h flex-vc flex-jsb">
      <div class="left">
        <ChartNum
          name="授予记录数"
          :num="stock_stat.number"
          backgroundImage=""
          shadowColor=""
        />
        <ChartNum
          v-for="(item, index) in stockStatRe"
          :key="index"
          :name="item.name"
          :num="item.percent"
          :backgroundImage="item.lineColor"
          :shadowColor="item.shadowColor"
        />
      </div>
      <div class="chart-box">
        <RingEchart :data="stockStat" ele="stock" />
      </div>
    </div>
  </div>
</template>
<script>
import ChartNum from "@/components/business/chart-num";
import RingEchart from "@/components/charts/RingEchart";
import api from "@/api";
export default {
  components: {
    ChartNum,
    RingEchart,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    ["data.user_id"](val, oval) {
      if (val !== oval) this.getDetail();
    },
  },
  computed: {
    optionStatRe() {
      const arr = JSON.parse(JSON.stringify(this.optionStat));
      return arr;
    },
    stockStatRe() {
      const arr = JSON.parse(JSON.stringify(this.stockStat));
      return arr;
    },
  },
  data() {
    return {
      option_stat: {},
      stock_stat: {},
      optionStat: [],
      stockStat: [],
      loading: true,
    };
  },
  mounted() {},
  methods: {
    getDetail() {
      const { user_id } = this.data;
      if (!user_id) {
        this.loading = false;
        return;
      }
      this.loading = true;
      api.participants
        .statUser({ user_id })
        .then((res) => {
          if (res) {
            // console.log(res);
            this.loading = false;
            const { option_stat, stock_stat } = res;
            this.option_stat = option_stat;
            this.stock_stat = stock_stat;
            if (option_stat) {
              this.optionStat = [
                {
                  name: "授予总数",
                  percent: Number(option_stat.award_qty),
                  color: [
                    {
                      offset: 0,
                      color: "#FF1960", // 0% 处的颜⾊
                    },
                    {
                      offset: 1,
                      color: "#FF6B99", // 100% 处的颜⾊
                    },
                  ],
                  lineColor:
                    "linear-gradient(90deg, #FF1960 0%, #FF6B99 100%);",
                  shadowColor: "rgba(255,107,153,0.5)",
                },
                {
                  name: "成熟总数",
                  percent: Number(option_stat.mature_qty),
                  color: [
                    {
                      offset: 0,
                      color: "#F06000", // 0% 处的颜⾊
                    },
                    {
                      offset: 1,
                      color: "#FF9651", // 100% 处的颜⾊
                    },
                  ],
                  lineColor:
                    "linear-gradient(90deg, #F06000 0%, #FF9651 100%);",
                  shadowColor: "rgba(255,160,97,0.5)",
                },
                {
                  name: "行权总数",
                  percent: Number(option_stat.actual_qty),
                  color: [
                    {
                      offset: 0,
                      color: "#57EB99", // 0% 处的颜⾊
                    },
                    {
                      offset: 1,
                      color: "#0DCD63", // 100% 处的颜⾊
                    },
                  ],
                  lineColor:
                    "linear-gradient(270deg, #57EB99 0%, #0DCD63 100%);",
                  shadowColor: "rgba(87,235,153,0.5)",
                },
              ];
            }
            if (stock_stat) {
              this.stockStat = [
                {
                  name: "授予总数",
                  percent: Number(stock_stat.award_qty),
                  color: [
                    {
                      offset: 0,
                      color: "#866BFF", // 0% 处的颜⾊
                    },
                    {
                      offset: 1,
                      color: "#5A39EB", // 100% 处的颜⾊
                    },
                  ],
                  lineColor:
                    "linear-gradient(180deg, #866BFF 0%, #5A39EB 100%);",
                  shadowColor: "rgba(134,107,255,0.5)",
                },
                {
                  name: "归属总数",
                  percent: Number(stock_stat.actual_qty),
                  color: [
                    {
                      offset: 0,
                      color: "#FF2699", // 0% 处的颜⾊
                    },
                    {
                      offset: 1,
                      color: "#FF77BF", // 100% 处的颜⾊
                    },
                  ],
                  lineColor:
                    "linear-gradient(90deg, #FF2699 0%, #FF77BF 100%);",
                  shadowColor: "rgba(255,160,97,0.5)",
                },
              ];
            }
          }
          // console.log(res);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.right-sta {
  height: 100%;
  overflow-y: auto;
  .chart_title {
    margin-bottom: 17px;
  }
  /deep/ .tip-text {
    margin-left: 4px;
    margin-bottom: 0.05em;
  }
  /deep/ .chart-num {
    margin-bottom: 12px;
  }
}
</style>
